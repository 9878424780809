import React, { useState } from 'react'
import { graphql } from 'gatsby'

import Intro from 'Components/pricing/Intro'
import PricingTabs from 'Components/pricing/PricingTabs'
import CurrencySwitcher from 'Components/pricing/CurrencySwitcher'
import Features from 'Components/pricing/Features'
import Divider from 'Components/common/Divider'
import PricingFAQ from 'Components/pricing/PricingFAQ'
import Clients from 'Components/common/Clients'

interface IProps {
  location: any
  history: any
  data: {
    bgImage: {
      [key: string]: string
    }
    stringsJson: {
      [key: string]: any
    }
  }
}

const Pricing: React.FC<IProps> = ({
  data: {
    bgImage,
    stringsJson: { pricing, home },
  },
  location,
  history,
}) => {
  const [currentCurrency, setCurrentCurrency] = useState('USD')

  return (
    <>
      <Intro
        bgImage={bgImage.childImageSharp.fixed}
        header={pricing.header}
        lang={pricing.lang}
      />
      <PricingTabs
        tabs={pricing.tabs}
        currency={currentCurrency}
        location={location}
        history={history}
      />
      <CurrencySwitcher
        currencies={pricing.currencySwitcher}
        currentCurrency={currentCurrency}
        setCurrentCurrency={setCurrentCurrency}
      />
      <Features features={pricing.features} />
      <Divider />
      <PricingFAQ faq={pricing.faq} />
      <Clients headerId={home.clients.header}
               usaId={home.clients.languages.usa}
               ukId={home.clients.languages.uk}
               noId={home.clients.languages.no}
               moreId={home.clients.more} />
    </>
  )
}

export default Pricing

export const query = graphql`
  query PricingQuery($lang: String!) {
    stringsJson(lang: { eq: $lang }) {
      ...HomeAndPricing
    }
    bgImage: file(relativePath: { eq: "contact/contact-intro-bg@2x.png" }) {
      childImageSharp {
        fixed(width: 2560, quality: 100) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
  }
`
